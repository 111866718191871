<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('administration.table.settings.empty_text')"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(user)="data">
          <p>
            <b>Користувач:</b> {{ data.item.user }}
            <template v-if="data.item.phone">
              , {{ data.item.phone }}
            </template>
            <template v-if="data.item.email">
              , {{ data.item.email }}
            </template>
          </p>
          <p v-if="data.item.message">
            <b>Повідомлення:</b> {{ data.item.message }}
          </p>
        </template>

        <template #cell(type)="data">
          <span
            v-if="data.item.status === 'specification'"
            class="btn btn-success btn-sm"
          >
            <feather-icon
              icon="AlertTriangleIcon"
              size="16"
              class="align-middle"
            />
          </span>
          {{ $t(`messages.types.${data.item.type}`) }}
          <br>
          <b v-if="data.item.type === 'notify' && data.item.product_id">
            {{ data.item.product }}
          </b>
        </template>

        <template #cell(status)="data">
          <div class="d-flex flex-column align-items-end justify-content-end">
            <b-badge
              variant="primary"
              v-text="$t(`orders.statuses.${data.item.status}`)"
            />
            <div class="mt-1 d-flex justify-content-end">
              <span
                v-if="(data.item.status === 'specification' || data.item.status === 'new') && $ability.can('show', 'reviews')"
                class="btn btn-success btn-sm"
                @click="changeStatus(data.item.id, 'completed')"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="16"
                  class="align-middle"
                />
              </span>
              <span
                v-if="(data.item.status === 'new') && $ability.can('show', 'reviews')"
                class="btn btn-warning btn-sm ml-1"
                @click="changeStatus(data.item.id, 'inwork')"
              >
                <feather-icon
                  icon="FastForwardIcon"
                  size="16"
                  class="align-middle"
                />
              </span>
              <span
                v-if="$ability.can('show', 'reviews')"
                class="btn btn-danger btn-sm ml-1"
                @click="destroy(data.item.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  class="align-middle"
                />
              </span>
            </div>
          </div>
        </template>

      </b-table>
      <div
        v-show="!frame"
        class="mx-2 mb-2"
      >
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('administration.table.settings.showing') }} {{ dataMeta.from }} {{ $t('administration.table.settings.to') }} {{ dataMeta.to }} {{ $t('administration.table.settings.of') }} {{ dataMeta.of }} {{ $t('administration.table.settings.entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BBadge,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onUnmounted } from '@vue/composition-api'
import SweetAlertMixin from '@mixins/SweetAlertMixin'
import useList from './useList'
import storeModule from './storeModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BBadge,
  },
  mixins: [SweetAlertMixin],
  props: {
    frame: {
      type: [Boolean, null],
      default: null,
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'created_at', label: this.$t('administration.table.fields.created_at'), sortable: false },
        { key: 'type', label: this.$t('administration.table.fields.type'), sortable: false },
        { key: 'user', label: this.$t('administration.table.fields.user'), sortable: false },
        { key: 'status', label: this.$t('administration.table.fields.status'), sortable: false },
      ],
    }
  },
  methods: {
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/administration/messages/${id}`)
          .then(() => this.refetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
    changeStatus(id, status) {
      this.$http.put(`/api/administration/messages/${id}/${status}`)
        .then(() => this.refetchData())
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'messages'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
    } = useList()

    return {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
